import React from 'react'
import MedicineTemplate from 'components/templates/MedicineTemplate/MedicineTemplate'

export default function MedicinePreEmploymentVisitsPage() {
  const seoKeywords = ['SEO0', 'SEO1', 'SEO2']

  const productName = 'Visite Pre Assuntive'

  const description =
    'La <b>visita preassuntiva</b> rientra tra quelle previste dalla sorveglianza sanitaria, affinché il medico competente di una determinata azienda possa verificare lo stato di salute e l’idoneità alla mansione specifica dei suoi lavoratori.<br> La visita medica preassuntiva è molto <b>vantaggiosa per il datore di lavoro</b>, perché gli consente di allontanare dall’azienda chi non ha i requisiti fisici necessari a compiere una determinata mansione, prima ancora che siano avviate le pratiche per la sua assunzione. Ciò vuol dire un grande risparmio. Oltretutto in questa maniera il datore di lavoro non è obbligato a spostare il lavoratore non idoneo ad altra mansione, anche inferiore, riservandogli lo stesso trattamento di prima, com’è invece previsto dalla legge per chi è stato già assunto ed è sottoposto a sorveglianza sanitaria.<br><br> ING GROUP, attraverso la sua fitta rete di medici del lavoro e centri medici sparsi su tutto il territorio nazionale, è in grado di gestire in tempi brevissimi visite preassuntive anche singole.'

  return (
    <>
      <MedicineTemplate seoKeywords={seoKeywords} productName={productName} description={description} />
    </>
  )
}
